<template>
  <div class="font-sans antialiased" id="app">
    <nav class="bg-krypto-primary">
      <div class="flex items-center justify-between flex-wrap p-4 lg:pt-4 lg:pb-4 lg:container lg:max-w-xl lg:mx-auto xl:max-w-3xl xl:px-0 xl:container xl:mx-auto">
        <div>
          <router-link to="/" class="block no-underline text-white">
            <div class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <g fill="none" fill-rule="evenodd">
                  <circle cx="12" cy="12" r="12" fill="#FFF"/>
                  <path fill="#6A65D8" d="M6.99,12.165 L10.305,9 L11.79,9 L8.295,12.21 L12.165,16.02 L10.635,16.02 L6.99,12.33 L6.99,16.02 L6,16.02 L6,9 L6.99,9 L6.99,12.165 Z M15.405,16.02 L12,9 L13.185,9 L17.685,9 L18.81,9 L15.405,16.02 Z"/>
                </g>
              </svg>
              <div class="pl-2 pr-4 pb-1 font-normal text-xl text-white">kryptoverket</div>
            </div>
          </router-link>
        </div>
        <div class="block sm:hidden">
          <button @click="toggle" class="flex items-center px-3 py-2 text-white hover:text-white">
            <svg class="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
          </button>
        </div>
        <div :class="open ? 'block': 'hidden'" class="w-full sm:flex sm:items-center sm:w-auto">
          <div class="text-md ml-1 pt-4 sm:justify-end sm:pt-0 sm:ml-0 sm:flex-grow">
            <router-link to="/sa-har-vi-raknat" class="block sm:pt-0 sm:inline-block no-underline text-krypto-tripple text-md pt-4 mr-4">
              <span class="hover:text-white">Så har vi räknat</span><span class="invisible sm:visible ml-4 opacity-50">|</span>
            </router-link>
            <router-link to="/faq" class="block sm:pt-0  sm:inline-block no-underline text-krypto-tripple text-md pt-4 mr-4">
              <span class="hover:text-white">FAQ</span><span class="invisible sm:visible ml-4 opacity-50">|</span>
            </router-link>
            <router-link to="/om" class="pt-4 block sm:pt-0 sm:inline block no-underline text-krypto-tripple hover:text-white text-md">
              Om oss
            </router-link>
          </div>
        </div>     
      </div>
    </nav>
    <div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import TransactionsView from '@/components/TransactionsView'
import '@/assets/styles/main.css'

export default {
  name: 'App',
  data(){
    return{
      open: false,
    }
  },
  methods:{
    toggle(){
      this.open = !this.open
    }
  },
  components: {
    TransactionsView
  },
  metaInfo: {
    // Children can override the title.
    title: 'Kryptoverket',
    // Result: My Page Title ← My Site
    // If a child changes the title to "My Other Page Title",
    // it will become: My Other Page Title ← My Site
    titleTemplate: null,
    // Define meta tags here.
    meta: [
      {charset: 'utf-8' },
      {name: 'viewport', content: 'width=device-width, initial-scale=1'},
      {name: 'description', content: 'En enkel tjänst för att hålla koll på skatten för dina kryptovalutor'},
      {name: 'keywords', content: 'krypto, skatt, skatta, kryptovalutor, bitcoin, ethereum, cointracking, deklarera, skatteverket, sverige, ico, transaktion, omkostnadsbelopp, beräkna'},
      // OpenGraph data (Most widely used)
      {property: 'og:title', content: 'Kryptoverket'},
      {property: 'og:site_name', content: 'Kryptoverket'},
      // The list of types is available here: http://ogp.me/#types
      {property: 'og:type', content: 'website'},
      // Should the the same as your canonical link, see below.
      {property: 'og:url', content: 'https://www.kryptoverket.se/'},
      {property: 'og:image', content: 'https://www.kryptoverket.se/kryptoverket.png'},
      // Often the same as your meta description, but not always.
      {property: 'og:description', content: 'En enkel tjänst för att hålla koll på skatten för dina kryptovalutor'},

      // Twitter card
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: 'https://www.kryptoverket.se/'},
      {name: 'twitter:title', content: 'Kryptoverket'},
      {name: 'twitter:description', content: 'En enkel tjänst för att hålla koll på skatten för dina kryptovalutor'},
      // Your twitter handle, if you have one.
      //{name: 'twitter:creator', content: '@alligatorio'}
      {name: 'twitter:image:src', content: 'https://www.kryptoverket.se/kryptoverket.png'},

      // Google / Schema.org markup:
      {itemprop: 'name', content: 'Kryptoverket'},
      {itemprop: 'description', content: 'En enkel tjänst för att hålla koll på skatten för dina kryptovalutor'},
      {itemprop: 'image', content: 'https://www.kryptoverket.se/kryptoverket.png'}
    ],
    links: [
      {rel: 'canonical', href: 'https://www.kryptoverket.se/'}
    ]
  }
}
</script>


