<template>
  <div>
    <div class="lg:pt-50 pb-50" id="bg-krypto-grad">
      <h1 class="text-white text-xl text-center pt-8 lg:pt-0 lg:text-3xl font-mono font-bold">
       Vad blir din kryptoskatt?
      </h1>
      <h2 class="text-white text-base lg:text-xl text-center font-normal pb-6 sm:pb-12 pt-3 px-8">
        En enkel tjänst för att beräkna <br class="hidden lg:hidden"/>skatten på dina kryptovalutor
      </h2>
    </div>
    <div class="xl:container xl:flex  xl:max-w-3xl xl:pr-0 xl:pl-0 lg:container lg:max-w-xl lg:mx-auto lg:px-6">
      <div class="flex mb-4 flex-col mr-4 ml-4 lg:mr-0 lg:ml-0">
        <div class="xl:flex lg:flex shadow-lg rounded-lg bg-white z-40 pl-6 pb-6 pr-6 pt-4 md:pb-6 mt-2 -mt-12 text-sm lg:text-base mb-4">
          <div class="lg:pr-4 pb-4">
            <h1 class="text-1xl sm:text-2xl">
              Ladda upp dina transaktioner 
            </h1>
            <h4 class="text-black font-normal pb-4">
            Genom att ladda upp en CSV-fil över dina kryptotransaktioner så beräknas skatten på dina kryptovalutor. Dessutom genereras en tabell som går att använda som underlag för deklaration hos Skatteverket. 
          </h4>
            <h3>
              Format på CSV-fil
            </h3>
            <h4 class="text-black font-normal pb-4">
              Varje rad i CSV-filen ska motsvara en transaktion. Tabellen ska ha följande sju kolumner: <br/>
              <pre class="border-l-4 border-krypto-tripple"><code style="display:block;overflow-x:auto;margin-top:1em;margin-left:1em;font-size:12px;padding:0.5em;background:#FFFFFF;color:#434f54">Antal köpta | Köpvaluta | Antal sålda | Säljvaluta | 
Avgift | Avgiftsvaluta | Datum (yyyy-mm-dd <span style="color:#6a65d8">hh:mm</span><span style="color:#6a65d8">:ss</span>)</code></pre>
            </h4>
            <h3>
              CSV-fil från cointracking.info
            </h3>
            <h4 class="text-black font-normal">
              Har du använt <a href="https://cointracking.info/">cointracking.info</a> kan du exportera dina transaktioner via "Enter Coins -> Overview & Manual Import -> Export (CSV)". CSV-filen går att använda precis som den är och behöver inte formateras om. 
            </h4>
          </div>
          <div class="lg:w-full">
            <div class="md:border-dashed md:border-krypto-tripple md:border-2 md:h-64 lg:h-full rounded h-full md:flex justify-center">
              <div class="md:flex flex-col items-center justify-center">
                  <div>
                    <label class="cursor-pointer border-b-4 border-krypto-secondary bg-krypto-primary hover:bg-krypto-hover text-white font-semibold py-4 pl-3 pr-6 rounded w-full inline-flex items-center justify-center">
                      <svg class="fill-current mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="heroicon-ui" d="M13 5.41V17a1 1 0 0 1-2 0V5.41l-3.3 3.3a1 1 0 0 1-1.4-1.42l5-5a1 1 0 0 1 1.4 0l5 5a1 1 0 1 1-1.4 1.42L13 5.4zM3 17a1 1 0 0 1 2 0v3h14v-3a1 1 0 0 1 2 0v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3z"/></svg>
                      <span>Ladda upp CSV-fil</span>
                      <input class="hidden" id="fileInput" type="file" @change="upload" > 
                    </label>      
                  </div>
                  <div class="text-krypto-secondary mt-12 absolute z-40 pt-8" v-if="parseSuccess === true">
                    Gick bra att ladda upp fil
                  </div>
                  <div class="text-red text-xs sm:mt-4 text-center mx-6 z-40 pb-4 sm:pb-0 mt-4" v-else-if="wrongFileFormat === true">
                    Fel filformat. Säkerställ att det är en CSV-fil du valt att ladda upp.
                  </div>
                  <div class="text-red text-xs text-center mt-4 mx-6 z-40 mt-4" v-else-if="wrongCSVColumns === true">
                    Du verkar inte ha laddat upp en CSV-fil på önskvärt CoinTracking-format eller enligt det manuella format som vi efterfrågar. Se över dina kolumner i CSV-filen.
                  </div>
                  <div class="text-red text-xs mt-4 text-center mx-6 z-40 mt-4" v-else-if="wrongCTformat === true">
                    Vi stödjer ej CSV-formatet från CoinTracking som hämtas från "Reporting -> Trade Prices". Gå via "Enter coins -> Overview & Manual Import -> Export CSV" istället.
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-lg rounded-lg bg-white z-40 py-4 mt-2 pl-6 pr-6 text-sm lg:text-base mb-4">
            <div class="flex flex-row items-center justify-between">
              <div>
                <h3 class="text-1xl text-krypto-secondary font-medium">
                  Förenklad redovisning
                </h3>
              </div>
              <div>
                <div class="inline-block relative w-12 lg:w-105px">
                  <select v-model="selected" class="block appearance-none w-full bg-white border border-grey-light hover:border-krypto-secondary px-4 py-2 rounded leading-tight focus:outline-none focus:shadow-outline">
                    <option v-for="option in options" v-bind:key="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                  <div class="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                  </div>
                </div>
              </div>
            </div>
            <h4 class="text-black text-base font-normal pb-4">
              Denna tabell kan användas som underlag för deklaration hos Skatteverket.
            </h4>
          <div class="h-64 overflow-auto -mr-6 -ml-6">
            <table id="customers">
              <thead>
                <tr>
                  <th id="tCenter">#</th>
                  <th>Antal</th>
                  <th>Beteckning</th>
                  <th>Försäljningspris</th>
                  <th>Omkostnadsbelopp</th>
                  <th>Vinst/Förlust</th>
                </tr>
              </thead>
              <div v-if="loading" class="absolute w-full h-full bg-white">
                <div class="pt-12 absolute pl-4 sm:pl-0 sm:w-full sm:pt-8 flex sm:flex-col sm:items-center sm:justify-center sm:absolute">
                  <div class="pt-2">
                     <half-circle-spinner
                      :animation-duration="700"
                      :size="40"
                      :color="'#514BD1'"
                    />
                  </div>
                  <div class="text-xs pt-6 pl-4 sm:text-sm  sm:pl-0 sm:pt-4 text-krypto-secondary">
                    Beräknar skatt...
                  </div>                
                </div>
              </div>
              <div v-if="errorapitimeout" class="absolute w-full h-full opacity-75 bg-white">
                <div class="pt-12 absolute pl-4 sm:pl-0 sm:w-full sm:pt-8 flex sm:flex-col sm:items-center sm:justify-center sm:absolute">
                  <div class="text-xs pt-6 pl-4 sm:text-sm  sm:pl-0 sm:pt-4 text-red">
                    Tekniskt fel. Vi kan inte hämta växelkurserna för alla dina transaktioner. 
                  </div>                
                </div>
              </div>
              <div v-else-if="errormissingvalues" class="absolute w-full h-full opacity-75 bg-white">
                <div class="pt-12 absolute pl-4 sm:pl-0 sm:w-full sm:pt-8 flex sm:flex-col sm:items-center sm:justify-center sm:absolute">
                  <div class="text-xs pt-6 pl-4 sm:text-sm  sm:pl-0 sm:pt-4 text-red">
                    Något gick fel. Säkerställ att inga värden saknas i CSV-filen. 
                  </div>                
                </div>
              </div>
              <tbody v-if="done">
                <tr v-for="(output, key, index) in outputData" :key="index">
                  <td id="tCenter">{{key}}</td>
                  <td>{{output.sell}}</td>
                  <td>{{output.sellcurrency}}</td>
                  <td>{{output.SEK}}</td>
                  <td>{{output.omkostnad}}</td>
                  <td>{{output.vinstforlust}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="firstSuccess" class="flex flex-col-reverse -ml-6 -mr-6 lg:flex-row lg:flex pt-6 pr-4 pl-4 pb-4 border-t-2 border-krypto-secondary">
            <div class="lg:w-1/2 pr-4">
              <div class="hidden lg:block text-black text-lg font-semibold pb-1">
                Summering
              </div>
              <h4 class="text-black text-base font-normal pt-4 lg:pt-0 pb-4">
                <!-- Här kan du ladda ner samtliga transaktioner som CSV-fil om du önskar redovisa alla dina affärer var för sig, och inte endast den förenklade redovisningen.-->
                Här kan du ladda ner den förenklade redovisningen som CSV-fil. Snart blir det även möjligt att ladda ner samtliga affärer för fullständig redovisning.
              </h4>
              <div class="button border-2 text-sm border-krypto-primary hover:text-white hover:bg-krypto-hover hover:border-krypto-hover text-krypto-primary font-bold py-2 pl-3 pr-6 rounded inline-flex items-center cusor-pointer relative">
                 <input class="opacity-0 absolute pin-x pin-y w-full" type="button" @click="save" download>
                 <svg class="fill-current mr-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path class="heroicon-ui" d="M11 14.59V3a1 1 0 0 1 2 0v11.59l3.3-3.3a1 1 0 0 1 1.4 1.42l-5 5a1 1 0 0 1-1.4 0l-5-5a1 1 0 0 1 1.4-1.42l3.3 3.3zM3 17a1 1 0 0 1 2 0v3h14v-3a1 1 0 0 1 2 0v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-3z"/></svg>
                 <span>Ladda ner CSV-fil</span>
              </div>
            </div>
            <div class="lg:w-1/2 lg:pl-8 lg:pr-3">
              <div class="flex flex-row justify-between">
                <h4 class="pb-4 text-sm font-normal">
                  Summa vinster
                </h4>
                <h4 v-if="loading" class="pb-4 text-sm font-bold">
                  <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="4"
                    :dots-num="3"
                    color="#22292f"
                  />
                </h4>
                <h4 v-else-if = "errored" class="pb-4 text-sm font-bold">
                  <span class="font-normal">-</span>
                </h4>
                <h4 v-else class="pb-4 text-sm font-bold">
                  {{ outputVinst }} <span class="font-normal">SEK</span>
                </h4>
              </div>
              <div class="flex flex-row justify-between">
                <h4 class="pb-4 text-sm font-normal">
                  Summa förluster
                </h4>
                <h4 v-if="loading" class="pb-4 text-sm font-bold">
                  <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="4"
                    :dots-num="3"
                    color="#22292f"
                  />
                </h4>
                <h4 v-else-if = "errored" class="pb-4 text-sm font-bold">
                  <span class="font-normal">-</span>
                </h4>
                <h4 v-else class="pb-4 text-sm font-bold">
                  {{ outputForlust }} <span class="font-normal">SEK</span>
                </h4>
              </div>
              <div class="flex flex-row justify-between">
                <h4 class="pb-4 text-sm font-normal">
                  Kapitalvinstskatt
                </h4>
                <h4 class="pb-4 text-sm font-bold">
                  30 <span class="font-normal">%</span>
                </h4>
              </div>
              <div class="flex flex-row justify-between text-base lg:text-xl pt-2 pb-2 border-b-2 border-krypto-tripple">
                <div class="font-bold">
                  Total skatt 
                  <span id="lowercase_that">
                    {{ selected }}
                  </span>
                </div>
                <div v-if="loading" class="pt-2">
                  <hollow-dots-spinner
                    :animation-duration="1000"
                    :dot-size="8"
                    :dots-num="3"
                    color="#6A65D8"
                  />
                </div>
                <div v-else-if = "errored" class="font-bold text-krypto-secondary">
                  <span class="font-normal">-</span>
                </div>
                <div v-else class="font-bold text-krypto-secondary">
                  {{ outputSkatt }} <span class="font-normal">SEK</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Papa from 'papaparse'
import Blob from 'blob'
import FileSaver from 'file-saver'
import axios from 'axios'
import throttledQueue from 'throttled-queue'
import {HalfCircleSpinner} from 'epic-spinners'
import { HollowDotsSpinner } from 'epic-spinners'

export default {
  name: 'TransactionsView',
  components: {
    HalfCircleSpinner,
    HollowDotsSpinner
  },
  props: ['output'],
  data () {
    const currentYear = new Date().getFullYear();
    return {
      parseData: [],
      parseSuccess: false,
      outputData: [],
      outputDataUnfiltered: [],
      outputVinst: '',
      outputForlust: '',
      outputSkatt: '',
      info: '',
      loading: false,
      done: false,
      errored: false,
      errorapitimeout: false,
      errormissingvalues: false,
      wrongCSVColumns: false,
      wrongFileFormat: false,
      wrongCTformat: false,
      firstSuccess: false,
      selected: `År ${currentYear}`,
      options: [
        // Create option objects for the previous four years
        { text: `År ${currentYear - 4}`, value: `${currentYear - 4}` },
        { text: `År ${currentYear - 3}`, value: `${currentYear - 3}` },
        { text: `År ${currentYear - 2}`, value: `${currentYear - 2}` },
        { text: `År ${currentYear - 1}`, value: `${currentYear - 1}` },
        // Create an option object for the current year
        { text: `År ${currentYear}`, value: `${currentYear}` },
        // Create option objects for one year in the future
        { text: `År ${currentYear + 1}`, value: `${currentYear + 1}` }
      ]
    }
  },
  created: function () {
      this.loading = false
      this.done = false
  },
  watch:{
    selected: function(){


      var that = this
      that.outputVinst = null
      that.outputForlust = null
      that.outputSkatt = null
      that.outputData = null

      that.summarizedata(that.outputDataUnfiltered)


    }
  },

  methods: {

    //      
    //    Uppladdning av fil
    //
    upload (e) {
      var that = this
      var file = e.target.files[0]
      that.parseSuccess = false
      that.wrongFileFormat = false
      that.errored = false
      that.errormissingvalues = false
      that.errorapitimeout = false
      that.done = false
      if(file.name.split(".").pop() != 'csv'){
          that.wrongFileFormat = true
          that.$ga.event({
            eventCategory: 'upload',
            eventAction: 'click',
            eventLabel: 'fileInput (Wrong File Format)'
          })
      }else{

        //      
        //    Parsar CSV till JSON
        //

        Papa.parse(file, {
          header: true,
          delimiter: '',
          beforeFirstChunk: function (chunk) {

            that.wrongCSVColumns = false
            that.wrongCTformat = false
            var rows = chunk.split(/\r\n|\r|\n/)
            var nrOfCols = rows[0].match(/"[^"]*"|[^,]+/g).length

            if (nrOfCols === 7) {
                rows[0] = '"buy","buycurrency","sell","sellcurrency","fee","feecurrency","date"'
                that.$ga.event({
                  eventCategory: 'upload',
                  eventAction: 'click',
                  eventLabel: 'fileInput(CSV from: Manual 7 columns)'
                })
            }else if (nrOfCols === 11){
                rows[0] = '"type","buy","buycurrency","sell","sellcurrency","fee","feecurrency","exchange","group","comment","date"'

                for (var count = 1; count < rows.length; count++) {
                    if(!rows[count].startsWith("Trade") & !rows[count].startsWith("Trade",1)){
                      delete rows[count]
                    };
                }

                rows = rows.filter(function (el) {
                  return el != null;
                });
                
                that.$ga.event({
                  eventCategory: 'upload',
                  eventAction: 'click',
                  eventLabel: 'fileInput(CSV from: "Enter Coins -> Overview & Manual")'
                })
            }else if(nrOfCols === 14){
                that.wrongCTformat = true
                that.$ga.event({
                  eventCategory: 'upload',
                  eventAction: 'click',
                  eventLabel: 'fileInput(CSV from: "Reporting -> Trade Prices")'
                })
            }else{
              that.wrongCSVColumns = true
              that.$ga.event({
                eventCategory: 'upload',
                eventAction: 'click',
                eventLabel: 'fileInput (Non supported CSV format)'
              })
            }

            return rows.join('\r\n')

          },
          complete (results) {

            if(that.wrongFileFormat === true || that.wrongCSVColumns === true || that.wrongCTformat === true){
              that.parseSuccess = false
            }else{
              that.parseSuccess = true
              that.$ga.event({
                eventCategory: 'upload',
                eventAction: 'click',
                eventLabel: 'fileInput (Success)'
              })
              that.parseData = results.data
              that.calculation()
            }
          },
          error (errors) {
            that.parseSuccess = false
          }
        })

      }
    },
    calculation (){

          var that = this

          var taxYear = that.selected

          var transData = that.parseData

          function getapiurls(transData){
    
            var stablecurrencies = {"SEK":1, "EUR":1, "ETH":1, "BTC":1, "LTC":1};
            var lookup = {};
            var currenciessold = [];

            for (var key in transData) {

                transData[key].buy = Number(transData[key].buy);
                transData[key].sell = Number(transData[key].sell);
                transData[key].fee = Number(transData[key].fee);

                var sellcurrency = transData[key].sellcurrency;
                var buycurrency = transData[key].buycurrency;
                var feecurrency = transData[key].feecurrency;


                if (!(sellcurrency in lookup)) {
                    lookup[sellcurrency] = 1;
                    currenciessold.push(sellcurrency);
                }
                var json_obj = []
                if(sellcurrency in stablecurrencies){
                    var exchangecurrency = sellcurrency
                    transData[key].exchangecurrencytype = "sellcurrency"
                }else{
                    if(buycurrency in stablecurrencies){
                        var exchangecurrency = buycurrency
                        transData[key].exchangecurrencytype = "buycurrency"
                    }else{
                        var exchangecurrency = sellcurrency
                        transData[key].exchangecurrencytype = "sellcurrency"
                    }
                }
                transData[key].exchangecurrency = exchangecurrency;
                transData[key].apiurl = 
                "https://min-api.cryptocompare.com/data/pricehistorical?fsym="+ exchangecurrency +"&tsyms=SEK&ts="+ Math.round(new Date(transData[key].date).getTime()/1000) 
                +"&api_key=de2e1a12c9bab2df5c71a3a3ef5f779582e9a8c170ac713cdeee264136fa9bd7"

            }
            return(currenciessold);
          }

          var currenciessold = getapiurls(transData);


          // Functions after API calls are done:
          function pair_apioutput_with_inputobject(results, transData){
            for(var key1 in transData){
              for(var key2 in results){
                if(transData[key1].apiurl == results[key2].request.responseURL){
                  var excurrency = transData[key1].exchangecurrency
                  if(typeof results[key2].data[excurrency] !== 'undefined' && typeof results[key2].data[excurrency].SEK !== 'undefined'){
                    var exchangerate = results[key2].data[excurrency].SEK;
                    transData[key1].exchangerate = exchangerate;                 
                  }else{
                    console.log("undefined")
                    that.loading = false
                    that.errored = true
                    that.errormissingvalues =true

                  }

                }
              }
            }
            return(transData)
          }

          function calculate_transaction_value_in_sek(transData){
            for(var key in transData){
              if(transData[key].sellcurrency == transData[key].exchangecurrency){
                transData[key].SEK = transData[key].sell * transData[key].exchangerate 
              }else{
                transData[key].SEK = transData[key].buy * transData[key].exchangerate 
              }
              if(transData[key].feecurrency == transData[key].exchangecurrency){
                transData[key].feeSEK = transData[key].fee * transData[key].exchangerate 
              }else{
                transData[key].feeSEK =  0;
              }
              
            }
            return(transData)
          }


          // API calls:
          var throttle = throttledQueue(5, 1000); // at most make 15 request every second.

          function handleResults(results){
            transData = pair_apioutput_with_inputobject(results, transData);
            transData = calculate_transaction_value_in_sek(transData)
            
            // Skriv ut resultattabell
            var outputdata = dotherest(currenciessold, transData);
            
            that.outputDataUnfiltered = outputdata

            var outputresult = that.summarizedata(outputdata); 

            that.loading = false
            if(!that.errored){
              that.done = true
              that.firstSuccess = true
            }
            that.outputData = outputresult
            
            

          }

          // TBD:
          function handleError(error){
            console.log("Error in resp")
            that.loading = false
            that.errored = true
            that.errorapitimeout = true

          }


          function asyncAction(myUrl) {
            return new Promise(function(resolve, reject) {
              throttle(function() {
                var result = axios.get(myUrl);
                return resolve(result);
              })    
            });
          }

          var promises = [];
          for (var i=0; i < transData.length; i++) {
            var myUrl = transData[i].apiurl;
            that.loading = true
            promises.push(asyncAction(myUrl));
          }

          axios.all(promises).then(function AcceptHandler(results) {
              handleResults(results);
          }, function ErrorHandler(error) {
              handleError(error);
          });

          // Funktion for att sortera i datumordning
          function custom_sort(a, b) {
              return new Date(a.date).getTime() - new Date(b.date).getTime();
          }


          function dotherest(currenciessold, transData){

            // Berakna omkostnadsbelopp:

            var myStringArray = currenciessold;
            var arrayLength = myStringArray.length;
            var outputdata = [];
            for (var i = 0; i < arrayLength; i++) {
                if(currenciessold[i] !== "EUR" && currenciessold[i] !== "SEK"){
                    var selljson = transData.filter(a=>a.sellcurrency==currenciessold[i]);
                    var buyjson = transData.filter(a=>a.buycurrency==currenciessold[i]);
                    selljson.sort(custom_sort)
                    buyjson.sort(custom_sort)

                    var omkostnad = 0;
                    var bought = 0;
                    var cost = 0;

                    var j = 0;

                    for(var key in selljson){

                        var amountsold = Number(selljson[key].sell);

                        while(j< buyjson.length && new Date(selljson[key].date).getTime() > new Date(buyjson[j].date).getTime() ){
                            bought = Number(bought) + Number(buyjson[j].buy)
                            cost = cost + Number(buyjson[j].SEK) + buyjson[j].feeSEK
                            j++
                        }
                    omkostnad = (amountsold/bought)*cost;
                    cost = cost - omkostnad;
                    bought = bought - amountsold;


                    selljson[key].omkostnad = omkostnad;
                    selljson[key].vinstforlust = Math.round(selljson[key].SEK - omkostnad);
                    if(selljson[key].vinstforlust >= 0){
                        selljson[key].selltype = selljson[key].sellcurrency + "_vinst"
                    }else{
                        selljson[key].selltype = selljson[key].sellcurrency + "_forlust"
                    }
                    selljson[key].omkostnad
                }
                outputdata = outputdata.concat(selljson);
                }
            }

            for (var key in outputdata){
                //index","type","buy","buycurrency","bValueInBTC","bValueInSEK","sell","sellcurrency","sValueInBTC","sValueInSEK","spread","exchange","group",date"
                delete outputdata[key].index;
                delete outputdata[key].bValueInBTC;
                delete outputdata[key].bValueInSEK;
                delete outputdata[key].sValueInBTC;
                delete outputdata[key].sValueInSEK;
                delete outputdata[key].spread;
                delete outputdata[key].buy;
                delete outputdata[key].buycurrency;
                delete outputdata[key].comment;
                //delete outputdata[key].date;
                delete outputdata[key].exchange;
                delete outputdata[key].fee;
                delete outputdata[key].feeSEK;
                delete outputdata[key].feecurrency;
                delete outputdata[key].group;
                //delete outputdata[key].selltype;
                delete outputdata[key].type;
                delete outputdata[key].apiurl;
            }
            
            return(outputdata)
          }
    },
    summarizedata(outputdata){

      // Sammanfatta data pa Skatteverkets enklare satt:

      var that = this
      var taxYear = that.selected
      var startDate = new Date(taxYear+"-01-01").getTime()
      var endDate = new Date(taxYear+"-12-31").getTime()
      outputdata = JSON.parse(JSON.stringify(outputdata))
      var outputdatafiltered = outputdata.filter(function(a){
        var betweenDate = new Date(a.date).getTime();
        return (betweenDate >= startDate && betweenDate <= endDate);
      });
      
      // Funktion for att sortera i datumordning
      function custom_sort(a, b) {
          return new Date(a.date).getTime() - new Date(b.date).getTime();
      }

      var outputresult = outputdatafiltered.reduce(function(res, obj) {
          if (!(obj.selltype in res)){
              res.__array.push(res[obj.selltype] = obj);
          }
          else {
              res[obj.selltype].SEK += obj.SEK;
              res[obj.selltype].omkostnad += obj.omkostnad;
              res[obj.selltype].vinstforlust += obj.vinstforlust;
              res[obj.selltype].sell += Number(obj.sell);
          }

          return res;

      }, {__array:[]})
      .__array
      .sort(custom_sort);

      // Plocka bort kolumner som inte behovs
      var vinst = 0;
      var forlust = 0;
      var skatt = 0
      for (var key in outputresult){

          outputresult[key].omkostnad = Math.round(outputresult[key].omkostnad)
          outputresult[key].SEK = Math.round(outputresult[key].SEK)

          if(outputresult[key].vinstforlust >0){
              vinst = vinst + outputresult[key].vinstforlust
          }else{
              forlust = forlust + outputresult[key].vinstforlust
          }
      }

      if(vinst > Math.abs(forlust)){
        skatt = Math.round(0.3*(vinst + forlust));
      }else{
        skatt = Math.round(0.7*(vinst + forlust));
      } 

      //var skatt = Math.round(0.3*(vinst + forlust * 0.7));

      that.outputVinst = vinst
      that.outputForlust = forlust
      that.outputSkatt = skatt
      that.outputData = outputresult

      return(outputresult)
    },
    save () {
      // Sorterar CSV-fil så att det blir rätt kolumner
      var temp = this.outputData
      for (var key in temp){
        delete temp[key].selltype;
        delete temp[key].exchangecurrency
        delete temp[key].exchangecurrencytype
        delete temp[key].exchangerate
        delete temp[key].date
      }

      var csv = Papa.unparse(temp)



      var rows = csv.split(/\r\n|\r|\n/)
      rows[0] = 'Antal,Beteckning,Forsaljningspris,Omkostnadsbelopp,Vinst/Forlust'
      var csvOut = rows.join('\r\n')

      const blob = new Blob([csvOut], { type: 'text/csv' })
      FileSaver.saveAs(blob, 'forenkladredovisning.csv')
    }
  }
}

</script>
