import Vue from 'vue'
import Router from 'vue-router'
import TransactionsView from '@/components/TransactionsView'
import Raknat from '@/views/Raknat'
import About from '@/views/About'
import FAQ from '@/views/FAQ'
import Meta from 'vue-meta'

Vue.use(Meta)
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'TransactionsView',
      component: TransactionsView
    },
    {
      path: '/sa-har-vi-raknat',
      name: 'Raknat',
      component: Raknat
    },
    {
      path: '/om',
      name: 'About',
      component: About
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ
    }
  ]
})
