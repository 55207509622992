<template>
	<div>
		<div id="bg-krypto-grad">
			<div class="pb-10 pt-8 lg:py-60">
				<h1 class="text-white text-3xl text-center font-mono font-bold">
					Så har vi räknat
				</h1>
				<h2 class="text-white text-base font-normal text-center">
					Steg för steg
				</h2>
			</div>
		</div>
		<div class="container pt-4 md:pt-8 pb-4 pl-4 pr-4 xl:container xl:max-w-lg xl:pr-0 xl:pl-0 lg:container lg:max-w-md lg:mx-auto lg:px-4">
			<section class="pb-6">
				<h1>
					Skatt för kryptovalutor
				</h1>
				<p class="pb-2">
					Nedan visas information som står hos Skatteverket och deras avsnitt för kryptovalutor. Vi har utgått från detta när vi gör våra beräkningar.
				</p>
				<h4>Så här skriver Skatteverket</h4>
				<div id="special_text" class="font-normal">
					Om du till exempel har köpt och sålt kryptovaluta räknar du ut din vinst eller förlust så här:

					<pre class="my-4 border-l-4 border-krypto-tripple"><code style="display:block;overflow-x:auto;font-size:14px;padding:0.5em;margin-left:6px;background:white;color:black">Det du fått betalt <span style="color:#6a65d8">(försäljningspris)</span> <br/>- det du köpte för <span style="color:#6a65d8">(omkostnadsbelopp)</span> = din vinst eller förlust.</code></pre>

					Du som har gjort en vinst beskattas för hela vinsten. Du betalar 30 procent i skatt på din vinst. 

					Har du gjort en förlust är den avdragsgill till 70 procent.

					<br/>
					<div id="special_text" class="mt-4">
						Läs mer hos <a href="https://www.skatteverket.se/privat/skatter/vardepapper/andratillgangar/kryptovalutor.4.15532c7b1442f256bae11b60.html">Skatteverket</a>
					</div>
				</div>
			</section>
			<section>
				<h1>
					Våra beräkningar
				</h1>
				<p class="pb-2">
					Vi utgår från en tabell där varje rad motsvarar en transaktion mellan två valutor vid ett givet tillfälle, ibland också associerade med en transaktionsavgift. 
				</p>
				<h4>
					1. För varje transaktion tar vi reda på den aktuella växelkursen till SEK.
				</h4>
				<div id="special_text">
					För att ta reda på växlingskursen till SEK vid det aktuella tillfället då transaktionen ägde rum använder vi oss av cryptocompare.com som är ett API för växelkurser mellan kryptovalutor och vanliga valutor. 
					<div id="special_text" class="mt-4 pb-4">
							Både säljvalutan och köpvalutan kan användas för att ta fram växelkursen, men då många kryptovalutor är extremt volatila används i första hand stabilare valutor som växlingskurs (SEK, EUR, ETH, BTC och LTC). Dvs, om en transaktion har gjorts mellan ETH och ICX så kollar vi ETH:s växelkurs mot SEK vid det aktuella transaktionstillfället och inte växelkursen mellan ICX och SEK.
					</div>
				</div>
				<h4>
					2. Växelkursen används för att beräkna värdet av transaktionen.
				</h4>
				<div id="special_text">
					För att beräkna värdet av en transaktion multipliceras växelkursen med antalet kryptovaluta som köpts eller sålts (om växelkursen är baserad på köpvalutan så multipliceras växelkursen med antalet köpta, om växelkursen är baserad på säljvalutan så multipliceras växelkursen på antalet sålda). 
					<div id="special_text" class="mt-4 pb-4">
							Även avgiften beräknas som en kostnad i SEK, förutsatt att avgiften är specificerad i samma valuta som växelkursen.
					</div>
				</div>
				<h4>
					3. Omkostnadsbelopp beräknas för varje valuta och transaktion. 
				</h4>
				<p class="pb-4">
					Alla transaktioner sorteras först efter datum och därefter beräknas omkostnadsbelopp enligt <i>genomsnittsmetoden</i> för varje transaktion vid det givna tillfället. Om det finns en avgift associerad till en transaktion läggs även denna till som ett omkostnadsbelopp.
				</p>
				<h4>
					4. Transaktionerna gruppers per valuta samt vinst/förlust. 
				</h4>
				<p class="pb-4">
					I ett sista steg filtreras transaktionerna per valt taxeringsår och grupperas per valuta och vinst/förlust. 
				</p>
				<h1>
					Eget ansvar - disclaimer
				</h1>
				<p>
					Vi (kryptoverket.se) avsäger oss allt ansvar som har att göra med om skatteberäkningarna är helt korrekta eller inte. Det är på ditt egna ansvar att säkerställa att det som lämnas in till Skatteverket är korrekt. Tjänsten skall ses som ett hjälpverktyg snarare än ett facit. Vi sparar inte dina transaktioner eller uppgifter någonstans.  
				</p>			
			</section>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Raknat'
}

</script>
