import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics'

import '@/assets/styles/main.css'

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'

Vue.use(VueAnalytics, {
  id: 'UA-118971779-1',
  router,
  debug: {
  	enabled: !isProd,
  	sendHitTask: isProd
  }
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
