<template>
	<div>
		<div id="bg-krypto-grad">
			<div class="pb-10 pt-8 lg:py-60">
				<h1 class="text-white text-3xl text-center font-mono font-bold">
					FAQ
				</h1>
				<h2 class="text-white text-base font-normal text-center">
					Frequently Asked Questions
				</h2>
			</div>
		</div>
		<div class="container pt-8 md:pt-8 pb-4 pl-4 pr-4 xl:container xl:max-w-lg xl:pr-0 xl:pl-0 lg:container lg:max-w-md lg:mx-auto lg:px-4">
			<h1>
				Vanliga frågor och svar
			</h1>
			<section class="pb-6">
				<h3>
					Hur exporterar jag mina transaktioner hos Cointracking?
				</h3>
				<p class="pb-2">
					Har du använt <a href="https://cointracking.info/">cointracking.info</a> kan du exportera dina transaktioner genom att i deras meny gå till "Enter Coins -> Overview & Manual Import -> Export (CSV)"
				</p>
			</section>
			<section class="pb-6">
				<h3>
					Varför funkar det inte med min egna CSV-fil?
				</h3>
				<p class="pb-2">
					Antagligen så är din csv på fel format (har du kanske fel format på datumkolumnen?), men det skulle också kunna bero på att du har fel antal kolumner. 
				</p>
				<h4>
					Måste vara strukturerad enligt följande sju kolumner:
				</h4>
        <pre class="border-l-4 h-12 border-krypto-tripple"><code style="display:block;overflow-x:auto;margin-top:1em;margin-left:1em;font-size:14px;padding:0.5em;background:#FFFFFF;color:#434f54">Antal köpta | Köpvaluta | Antal sålda | Säljvaluta | 
Avgift | Avgiftsvaluta | Datum (yyyy-mm-dd <span style="color:#6a65d8">hh:mm</span><span style="color:#6a65d8">:ss</span>)</code>
				</pre>
			</section>
			<section class="pb-6">
				<h3>
					Kan jag se min kryptoskatt för tidigare räkenskapsår?
				</h3>
				<p class="pb-2">
					Ja, om du har laddat upp en fil som innehåller transaktioner från tidigare år så kan du använda rullistan för ändra till 2017, 2018 eller 2019. 
				</p>
			</section>
			<section class="pb-6">
				<h3>
					Räcker det med att ladda upp en CSV-fil <br class="sm:hidden"/>  med transaktioner från 2018 om jag vill beräkna skatten på 2018?
				</h3>
				<p class="pb-2">
					Om du har sålt kryptovaluta som du köpte tidigare än 2018 så måste du ha med köptransaktionen också, så det räcker generellt sett inte med transaktioner enbart från det året som du vill beräkna skatten på. 
				</p>
			</section>
			<section class="pb-6">
				<h3>
					Vad betyder det när det står ett negativt skattebesked?
				</h3>
				<p class="pb-2">
					Om ditt skattebesked är negativt så betyder det att du totalt sett har gjort en förlust under det valda året. En förlust som enligt skatteverket är avdragsgill upp till 70%. Läs mer hos <a href="https://www.skatteverket.se/privat/skatter/vardepapper/deklareraaktierochovrigavardepapper/kvittningochkvotering.4.7be5268414bea0646945a21.html">Skatteverket</a>.	 
				</p>
			</section>
			<section class="pb-6">
				<h3>
					Kan jag lita på Kryptoverket till 100%?
				</h3>
				<p class="pb-2">
					Tyvärr inte, det här är ett hemmabygge och vi vill vara tydliga med att vi inte tar på oss ansvaret för att din deklaration blir rätt.
				</p>
			</section>
			<section class="pb-6">
				<h3>
					Varför kan man inte koppla sina transaktioner direkt från stora exchanges som Coinbase och Binance?
				</h3>
				<p class="pb-2">
					Vi hoppas på att kunna lägga till detta i framtiden. 
				</p>
			</section>
		</div>
	</div>
</template>

<script>

export default {
	name: 'FAQ'
}

</script>
