<template>
	<div>
		<div id="bg-krypto-grad">
			<div class="pb-10 pt-8 lg:py-60">
				<h1 class="text-white text-3xl text-center font-mono font-bold">
					Om oss
				</h1>
				<h2 class="text-white text-base font-normal text-center">
					En historia
				</h2>
			</div>
		</div>
		<div class="container pt-4 md:pt-8 pb-4 pl-4 pr-4 xl:container xl:max-w-lg xl:pr-0 xl:pl-0 lg:container lg:max-w-md lg:mx-auto lg:px-4">
			<section class="pb-6">
				<h1>
					Så började det
				</h1>
				<p>
					Liksom många andra blev vi intresserade av kryptovalutor för ett tag sedan. Från början gjorde vi bara några enstaka köp, men med tiden blev transaktionerna fler och allt mindre överskådliga. Samtidigt var vi medvetna om att handeln med krypto skulle beskattas och började därför att läsa in oss på hur handeln med valutorna skulle deklareras. 
					<p class="mt-4 pb-4">
						Att deklarera visade sig vara mycket mer komplicerat än vi kunnat ana. Vi lärde oss att varje transaktion skulle beskattas och att det inte räckte att bara räkna på vinst/förlust efter att pengarna växlats tillbaka till kronor igen.
				</p>
				<h4>Gör det enkelt</h4>
				<p class="font-normal">
					När vi väl listat ut hur man skulle göra så väcktes idén om att skapa en webbtjänst där allt skulle räknas ut automatiskt. För även om kryptovärlden är hur intressant som helst så förtar det ju lite om man ska behöva lägga massvis med timmar på att deklarera en gång per år. Kryptoverket är tänkt att göra det enklare och roligare att investera i krypto.
				</p>
			</section>
		</div>
	</div>
</template>

<script>

export default {
	name: 'About'
}

</script>
